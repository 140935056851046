import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardDescription } from '../../../../components/molecules/Card';
import LinkText from '../../../../components/atoms/LinkText/linkText';
import { ICONS } from '../../../../constants/Icons';
import './_onboardingCard.less';

const OnboardingCard = ({ title, description, links }) => {
    const linkClassName = `onboarding-card__link ${
        links.length === 1 ? 'onboarding-card__link--no-padding' : ''
    }`;

    return (
        <Card modifier='onboarding-card'>
            <CardTitle title={title} />
            <CardDescription text={description} />
            <div className='onboarding-card__link-container'>
                {links.map((link) => (
                    <div
                        key={`${link.title}${link.route}`}
                        className={linkClassName}>
                        <LinkText
                            route={link.route}
                            text={link.title}
                            icon={link.icon ? ICONS[link.icon] : null}
                            iconPosition={link.iconPosition}
                            dataCy='onboarding-card'
                        />
                    </div>
                ))}
            </div>
        </Card>
    );
};

OnboardingCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object),
};

OnboardingCard.defaultProps = {
    links: [],
};

export default OnboardingCard;
