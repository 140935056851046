import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveContainer from '../../../layouts/ResponsiveContainer/responsiveContainer';
import './_pageSection.less';

const PageSection = ({ children, sectionModifier }) => (
    <section className={`page-section ${sectionModifier ? ` page-section--${sectionModifier}` : ''}`}>
        <ResponsiveContainer>
            {children}
        </ResponsiveContainer>
    </section>
);

PageSection.propTypes = {
    children: PropTypes.node.isRequired,
    sectionModifier: PropTypes.string,
};

PageSection.defaultProps = {
    sectionModifier: '',
}

export default PageSection;
